.main {
    padding-top: 120px;
    height: 100vh;
    display: flex;
    justify-content: flex-start; /* aligns children to start (left) */
    align-items: center;
    background: url(./images/main.jpg) no-repeat center center;
    background-size: cover;
    padding-left: 10%; /* gives some space from left */
    overflow: hidden;
}
/* Media query for mobile devices */
@media (max-width: 1000px) {
    .main {
        padding-top: 60px; /* Reduced top padding for mobile */
        padding-left: 5%; /* Reduced left padding for mobile */
        justify-content: center; /* Center content on smaller screens */
        align-items: flex-start; /* Align items to the start for better readability */
    }
}