.services {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: url(./images/plant.jpg) no-repeat center center;
    background-size: cover;
    overflow: hidden; /* Prevent content from overflowing */
}
  
.services h1 {
    margin-bottom: 50px;
}
  
.services-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 70%;
}
  
.service-box {
    position: relative; /* Add this line */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    height: 200px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.1);
    transition: all 0.3s ease-in-out;
    overflow: hidden; /* Add this line */
}
  
.service-box:hover {
    box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
    transform: translateY(-10px);
}
  
.service-icon {
    font-size: 2.5rem;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
}
  
.service-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
    transition: all 0.3s ease-in-out;
}

.service-box:hover .service-title {
    transform: translateY(-100px);
}
  
.service-description {
    opacity: 0;
    position: absolute;
    display: block;
    bottom: 30px;
    transform: translateY(100%); /* Add this line */
    transition: all 0.3s ease-in-out;
    list-style: disc inside; 
    padding-left: 1rem;
}
.service-description li {
    margin-top: 0;
    margin-bottom: 1rem;
}
  
.service-box:hover .service-description {
    opacity: 1;
    transform: translateY(0); /* Modify this line */
}
  
.service-box:hover .service-icon {
    opacity: 0;
    transform: translateY(-90px);
}
/* Media query for mobile devices */
@media (max-width: 1000px) {
    .services-grid {
        grid-template-columns: 1fr; /* Stack grid items vertically on mobile */
        width: 70%; /* Increase width for mobile layout */
        gap: 10px; /* Reduced gap for a more compact layout */
    }

    .service-box {
        height: 110px; /* Increased height for larger boxes */
        padding: 15px; /* Adjust padding for larger boxes */
    }

    .service-box:hover .service-description {
        font-size: 0.8rem; /* Smaller font size for hover text */
        opacity: 1; /* Make hover text visible */
        transform: translateY(25px); /* Reset transform */
    }

    .service-title,
    .service-icon {
        font-size: 1rem; /* Smaller font size for titles and icons */
    }

    /* Optional: Adjust hover effects for better mobile usability */
    .service-box:hover {
        transform: none;
    }
    .service-box:hover .service-title,
    .service-box:hover .service-icon {
        transform: none;
        opacity: 0; /* Keep elements visible */
    }
}