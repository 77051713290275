.footer {
    text-align: center;
    padding: 1rem;
    background-color: #f8f9fa;
    color: #6c757d;
    position: relative;
    bottom: 0;
    width: 100%;
  }
  
  .footer-linkedin {
    margin-left: 0.5rem; /* adjust as needed */
    color: #0077b5; /* LinkedIn blue color */
    transition: transform 0.3s ease; /* for hover animation */
    font-size: 20rem;
  }
  
  .footer-linkedin:hover {
    transform: scale(1.2); /* adjust as needed */
  }
  /* Media query for mobile devices */
@media (max-width: 1000px) {
  .footer {
      padding: 0.5rem; /* Reduced padding for a more compact footer */
  }

  .footer-linkedin {
      font-size: 5rem; /* Significantly reduced font size */
      margin-left: 0.2rem; /* Adjusted margin */
  }

  .footer-linkedin:hover {
      transform: scale(1.1); /* Slightly reduced scale effect */
  }
}