.about {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    background-color: #e0f8ff; /* Light blue */
    height: 70vh;
    background-size: cover;
    padding: 2% 10% 0 10%;
    overflow: hidden; /* Prevent content from overflowing */
}

.about-left {
    flex-basis: 30%;
    max-height: 100%;
}

.about-left h1 {
    color: #333; /* Dark Gray */
    font-size: 3rem;
    font-family: 'Open Sans', sans-serif;
    margin-top: 10%;
}

.about-divider {
    height: 95%;
    width: 5px;
    background-color: #333; /* Dark Gray */
}

.about-right {
    flex-basis: 45%;
    padding-right: 1rem;
    max-height: 100%; /* Ensure it doesn't exceed the parent height */
    overflow: auto; /* Add scroll on overflow */
}

.about-right p {
    font-size: 1.2rem;
    margin-top: 12rem;
    font-family: 'Open Sans', sans-serif;
}
.about-right h2.typing-text {
    position: relative;
    top: 37px; /* adjust as needed */
}
  .typing-text span {
    color: #28a745; 
  }
  .highlighted {
    color: #0eb435;
  }
  .about-img {
    border-radius: 10%;
    max-width: 100%;
    width: 100%;
    height: auto;
    margin-top: -1.5rem;
  }
  /* Media query for mobile devices */
@media (max-width: 1000px) {
  .about {
      flex-direction: column;
      align-items: center;
      padding: 5% 5%;
      height: auto;
  }

  .about-left, .about-right {
      flex-basis: 100%;
  }

  .about-left h1 {
      font-size: 2rem;
      margin-top: 5%;
  }

  .about-divider {
      display: none; /* Hide the divider on small screens */
  }

  .about-right p {
      font-size: 1rem;
      margin-top: 2rem;
  }

  .about-right h2.typing-text {
      top: 10px;
  }
}