.contact-container {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin: 2rem 4rem;
}

.contact-form-container {
    width: 45%;
    text-align: center; /* center the heading */
}

.contact-heading {
    margin-bottom: 1rem; /* add some space between the heading and the form */
}

.contact-form {
    width: 100%;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.contact-form button {
    padding: 1rem 2rem;
    border: none;
    border-radius: 4px;
    background-color: #28a745;
    color: white;
    cursor: pointer;
}

.contact-form button:hover {
    background-color: #218838; /* darker green for hover effect */
}

.contact-info {
    width: 45%;
    display: flex;
    flex-direction: column;
    margin-top: 4rem;
}

.info-item {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

.info-item p {
    margin-left: 1rem;
}
.contact-image {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 300px;
    height: auto;
    z-index: 1;
}

/* Mobile devices responsiveness */
@media (max-width: 1000px) {
    .contact-container {
        flex-direction: column;
        margin: 2rem 1rem;
    }

    .contact-form-container,
    .contact-info {
        width: 90%;
        margin-top: 2rem;
    }

    .contact-form button {
        padding: 1rem 4rem; /* Larger button for easier touch */
    }

    .contact-image {
        width: 100%; /* Full width of container */
        height: auto; /* Maintain aspect ratio */
        position: static; /* Regular flow in document */
    }
}