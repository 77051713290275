/* Navbar.css */

nav {
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1rem 5rem;
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    transition: padding 0.3s ease-in-out;
    z-index: 1000;
}
.navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.logo {
    width: 300px;
    height: 100px;
    transition: all 0.3s ease-in-out;
}

ul {
    list-style-type: none;
    display: flex;
    gap: 4rem;
    align-items: center;
}

li a {
    color: rgb(9, 60, 119);
    text-decoration: none;
    font-family: 'Ubuntu', sans-serif;
    font-size: 20px;
}

li a:hover {
    color: rgb(9, 60, 119); /* Bootstrap primary color */
    text-decoration: underline 5px;
    cursor: pointer;
}

.nav.shrunk {
    padding: 0.5rem 5rem; /* Smaller padding */
}

.logo.shrunk {
    width: 150px; /* Smaller logo */
    height: 50px;
}
.top-boxes {
    display: flex;
    gap: 20px; /* adjust this to control the space between boxes */
}
  
.top-box {
    width: 180px;
    height: 50px;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
    border: 1px solid rgb(9, 60, 119);
    cursor: pointer;
    font-family: 'Ubuntu', sans-serif;
    font-size: 16px;
    color: rgb(9, 60, 119);
    text-decoration: none; /* removes the default underline */
}

.top-box.shrunk {
    width: 180px; /* Smaller box width */
    height: 30px; /* Smaller box height */
}
.top-box:hover {
    color: rgb(9, 60, 119);
    text-decoration: underline 2px;
}
/* Mobile devices responsiveness */
@media (max-width: 1000px) {
    nav {
        position: relative; /* Navbar scrolls with the page */
        padding: 0.5rem 1rem; /* Reduced padding for mobile */
        align-items: center; /* Align items centrally for a less tall navbar */
    }

    .navbar-content {
        flex-direction: column; /* Stack logo and navigation vertically */
        align-items: center; /* Center-align items for a tidier appearance */
    }

    .logo {
        width: 120px; /* Further reduced logo width */
        height: 40px; /* Reduced logo height */
        margin-bottom: 0.5rem; /* Reduced gap between logo and navigation */
    }

    ul {
        width: 100%; /* Full width to accommodate vertical layout */
        gap: 0.5rem; /* Reduced gap for mobile */
        flex-direction: column; /* Links listed vertically */
        padding: 0; /* Remove padding, if any */
    }

    li a {
        font-size: 16px; /* Smaller font size for links */
    }

    .top-boxes {
        margin-top: 0.5rem; /* Reduced gap between navigation and top-boxes */
        gap: 0.5rem; /* Reduced gap for mobile */
        flex-direction: column; /* Stack top boxes vertically */
    }

    .top-box {
        width: 100%; /* Full width for a more mobile-friendly layout */
        height: 35px; /* Slightly reduced height */
        font-size: 14px; /* Smaller font size */
    }
}